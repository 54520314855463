body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif !important;
    // font-weight: 500;
}

.m-a {
    margin: auto !important;
}

.d-flex {
    display: flex;
}
.justify-space {
    justify-content: space-between;
}
.p-10 {
    padding: 10px;
}

.m-t-5 {
    margin-top: 5px;
}
.m-t-10 {
    margin-top: 10px !important;
}
.m-t-20 {
    margin-top: 20px !important;
}

.m-l-5 {
    margin-left: 5px;
}
.m-r-5 {
    margin-right: 5px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.error-messages {
    color: red;
    position: absolute;
    bottom: -18px;
    font-size: 13px;
    left: 2px;
}

.MuiButton-containedPrimary {
    &.Mui-disabled {
        background-color: #5d88b9 !important;
    }
}

.Mui-disabled {
    background-color: #dadada !important;
}

.MuiListItemIcon-root {
    min-width: 40px !important;
}

.go-back-button {
    width: fit-content;
    display: flex;
    padding: 6px 14px;
    border-radius: 5px;
    cursor: pointer;
    height: 24px;
    p {
        font-size: 14px;
        display: flex;
        align-items: center;
        svg {
            margin-top: -1px;
            font-size: 15px;
        }
    }
}

.universal-table {
    thead {
        tr {
            th {
                padding: 2px 5px !important;
                border-right: 1px solid rgb(196, 196, 196);
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 2px !important;
                font-size: 13px;
                svg {
                    font-size: 20px;
                    margin-right: 5px;
                    cursor: pointer;
                    &:last-child {
                        margin-right: 0px;
                    }
                }
                &:last-child {
                    // display: flex;
                    justify-content: center;
                }
            }
        }
    }
}

.main-advanced-loader {
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 999999;
    background: #3a3a3a75;
    overflow: hidden;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    .loader-wrapper {
        svg {
            color: #ffffff;
        }
        p {
            font-size: 26px;
            color: white;
            font-weight: 500;
            margin-top: 28px;
        }
    }
}
.MuiDialog-container {
    z-index: 998;
}

.MuiPagination-root {
    height: 80px;
    display: flex;
    float: right;

    ul {
        margin: auto;
    }
}

.pagination-button-div {
    // width: 100%;
    display: flex;
    // justify-content: end;
    float: right;
    overflow: hidden;
    margin-right: 20px;
    align-items: center;
    div {
        width: 30px;
        margin: 10px;
        padding: 5px 20px;
        cursor: pointer;
        text-align: center;
        &:hover {
            background-color: rgb(214, 214, 214);
        }
    }
    P {
        margin-right: 10px;
    }
    &:nth-child(1) {
    }
    &:nth-child(2) {
    }
}

.p-8 {
    // padding: 8px 0px;
}

.overflow-x-a {
    overflow-x: hidden;
}

.MuiToolbar-regular {
    min-height: 50px !important;
}

.h-100 {
    height: 100%;
}

.custom-error-alert {
    background-color: #ffc6c6;
    padding: 15px;
    border-radius: 10px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    min-width: 250px;
    min-height: 300px;
    z-index: 9999;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    div {
        display: flex;
        justify-content: space-between;
        svg {
            cursor: pointer;
            color: red;
        }
    }
    ul {
        list-style-type: decimal;
        padding-left: 20px;
        li {
            padding-bottom: 5px;
        }
    }
}

#mapid {
    height: 200px;
}

.drawer-icon-div {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.MuiAppBar-root {
    background-color: #363c61 !important;
}

.MuiTooltip-arrow {
    color: #4d4d4d !important;
}
