.main-parent-container {
    position: relative;
    min-height: calc(100vh - 64px);
   // background-color: rgb(246, 246, 255);
    padding-top: 52px;
    background-color: lightsteelblue;
}

.selected-route {
    background-color: #3f51b5 !important;
    color: white !important;
    svg {
        color: white;
    }
}

.login-container {
    height: 100vh;
    background-color: rgb(233, 233, 248);

    .welcome-login {
        background: #3f51b5;
        color: white;
        padding: 10px;
        /* font-size: 13px; */
        border-radius: 5px 5px 0px 0px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        h2 {
            font-weight: 100;
        }
    }

    .login-container-card {
        // margin: auto;
        background-color: #fff;
        border-radius: 0px 0px 5px 5px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        // height: 70vh;
        .login-heading {
            font-weight: 400;
            text-align: center;
        }
        .forgot-password {
            font-size: 14px;
            color: rgb(93, 93, 190);
            font-weight: 500;
            // text-align: end;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.dashboard-container {
    .dashboard-label {
        height: 40px;
        padding: 20px;
        text-align: center;
    }
    .click-on-paper {
        cursor: pointer;
        &:hover {
            background-color: rgb(235, 235, 235);
        }
    }
    .dashboard-menu-images {
        padding-top: 20px;
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }
}

.user-organization-contianer {
    .user-organization-actions {
        svg {
            cursor: pointer;
            font-size: 18px;
        }
        svg:nth-child(1) {
            color: #3f51b5 !important;
        }
        svg:nth-child(2) {
            color: rgb(226, 64, 64) !important;
        }
    }
}

.select-roles-list {
    list-style-type: none;
    padding-left: 10px;
}

.add-vessel-container {
    .item-box-contianer {
        display: flex;
        // justify-content: space-evenly;
        padding-top: 16px;
        overflow: auto;
        .upper-field-items {
            width: 100%;
            font-size: 12px;
            max-width: 69px;
            p {
                display: flex;
                align-items: center;
                width: 100%;
                height: 30px;
                padding: 5px 0px;
                background-color: rgb(230, 230, 230);
                text-align: center;
            }
            .upper-field-input {
                width: 60px;
                height: 20px;
                border: none;
                border-bottom: 1px solid grey;
                outline: none;
                margin-top: 6px;
                // border-left: 1px solid grey;
            }
        }
    }
    .upper-field-input {
        width: 100%;
        height: 20px;
        border: none;
        border-bottom: 1px solid grey;
        outline: none;
        margin-top: 6px;
        font-size: 12px;
        // border-left: 1px solid grey;
    }
    .bottom-p-tag {
        text-align: left;
        width: 100%;
        background-color: rgb(230, 230, 230);
        padding: 5px 0;
        font-size: 12px;
    }
    .port-consumptions-label {
        font-size: 12px;
        align-self: center;
        width: 100%;
        padding-left: 10px;
        background: rgb(230, 230, 230);
        height: 29px;
    }
    .port-consumptions-label2 {
        font-size: 12px;
        align-self: center;
        width: 100px;
        padding-top: 8px;
        padding-left: 10px;
        background: rgb(230, 230, 230);
        height: 29px;
    }
    .show-detail-vessel-table {
        table {
            background-color: #ffffff;
            padding: 10px;
            tr {
                th {
                    background-color: #e2e2e2;
                    border-radius: 4px;
                    text-align: end;
                    padding: 4px 8px;
                }
                td {
                }
            }
        }
    }
}

.add-order-container {
    .MuiOutlinedInput-input {
        padding: 11px;
        font-size: 14px;
    }
    .item-box-contianer {
        .upper-field-items {
            width: 100%;
            font-size: 12px;
            max-width: 69px;
            // overflow: hidden;
            p {
                display: flex;
                align-items: center;
                width: 100%;
                height: 30px;
                padding: 5px 0px;
                background-color: rgb(230, 230, 230);
                text-align: center;
            }
            .upper-field-input {
                width: 60px;
                height: 20px;
                border: none;
                border-bottom: 1px solid grey;
                outline: none;
                margin-top: 6px;
                background-color: rgb(242, 242, 255);
                // border-left: 1px solid grey;
            }
        }
    }
    .lower-input-grid {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-top: 20px;
        .input-div {
            width: 35%;
            .upper-item-div {
                width: 95%;
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
                p {
                    font-size: 14px;
                    align-self: center;
                    min-width: 115px;
                }
                .inputs {
                    width: 95%;
                }
            }
        }
        .textarea-div {
            width: 65%;
            .add-order-textarea {
                width: 98%;
                min-width: 98%;
                min-height: 92%;
                max-width: 98%;
                margin: 0px;
                font-size: 14px;
                font-family: "Roboto", sans-serif;
                outline: none;
                border-radius: 4px;
                padding: 5px;
                padding-left: 10px;
                &::placeholder {
                    color: rgb(194, 194, 194);
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .lower-input-grid {
        display: block;
    }
}

.calculator-section {
    font-size: 12px;
    .quick-menu-bar {
        height: 30px;
    }

    .calc-order-section {
        // border: 1px solid grey;
        height: calc(100vh - 80px);
        background-color: rgb(212, 212, 212);
        overflow: scroll;
        overflow-x: hidden;
        position: fixed;
        width: 140px;
        margin-top: 30px;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .calc-secound-section {
        // height: calc(100vh - 80px);
        // overflow: scroll;
        overflow: hidden;
        padding: 0px 0px 0px 8px;
        .upper-section-grid {
            height: 100%;
            // border: 1px solid grey;
            //background-color: white;
            background-color: #e6f7ff;
            border-radius: 6px;
            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 1px 3px 0px rgba(0, 0, 0, 0.12);
            // height: 350px;
            // padding: 0 10px 0px 0px;
        }
        .middle-section-grid {
            padding: 0 10px 0px 0px;
        }

        .bottom-section-grid {
            // border: 1px solid grey;
            // background-color: rgb(212, 212, 212);
            // height: 500px;
            padding: 0 10px 0px 0px;
        }
    }

    .lower-section-grid {
        padding: 10px;
        background-color: #ffffff;
        border-radius: 4px;
    }

    .order-spans {
        display: block;
        border: 1px solid rgb(146, 146, 146);
    }
    .orders-list-items {
        list-style: none;
        padding: 3px;
        li {
            margin-bottom: 5px;
            p {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                span {
                    display: block;
                    width: 100%;
                    padding: 3px 0px 3px 3px;
                    &:hover {
                        background-color: rgb(170, 170, 170);
                    }
                }
                svg {
                    font-size: 14px;
                    margin: auto;
                    color: #d3d3d3;
                    &:hover {
                        color: rgb(255, 82, 82);
                    }
                }
            }
            &.selected {
                background-color: rgb(170, 170, 170);
            }
        }
    }
}

.vessel-selection-table {
    // overflow-x: hidden !important;
    // overflow-x: clip !important;
    overflow-y: hidden !important;
    table {
        thead {
            tr {
                background-color: rgb(218, 218, 218);
                th {
                    padding: 3px 4px;
                    font-size: 13px !important;
                    line-height: inherit;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 0px;
                    padding-left: 4px;
                    font-size: 12px !important;
                    .MuiFormControl-root {
                        width: 140px !important;
                        .MuiInputBase-input {
                            font-size: 12px !important;
                            padding: 3px !important;
                        }
                    }
                    textarea {
                        border-radius: 3px;
                        border: 1px solid grey;
                        font-family: "Roboto", sans-serif;
                        height: 23px;
                        max-width: 148px;
                        margin: 0px;
                        width: 152px;
                        min-width: 148px;
                        min-height: 23px;
                    }
                }
            }
        }
    }
    &.selected-email-table {
        width: 100%;
        tbody {
            tr {
                td {
                    padding: 0px 5px;
                    font-size: 12px !important;
                    height: 30px;
                }
            }
        }
    }
}

.show-term-modal {
    display: flex;
    width: 60%;
    margin: auto;
    font-weight: normal;
    justify-content: space-between;
    margin-bottom: 5px;
    background-color: rgb(238, 238, 238);
    p {
        font-weight: normal;
        text-align: start;
    }
    p:nth-child(2) {
        width: 80px;
        margin-left: 10px;
    }
}

.port-operation-header {
    display: flex;
    background-color: rgb(212, 212, 212);
    width: fit-content;
    min-width: 100%;
    span {
        display: block;
        font-size: 12px;
    }
}

.port-operation-buttons {
    display: flex;
    justify-content: space-between;
    .add {
        width: fit-content;
        font-size: 13px;
        padding: 3px 7px;
        border-radius: 4px;
        color: white;
        cursor: pointer;
        margin-bottom: 5px;
        border: none;
        outline: none;
        background-color: #3f51b5;
    }

    .vessel-extra-actions {
        display: flex;
        .extra-actions-div {
            margin-right: 10px;
            span {
                margin-right: 5px;
            }
            input {
                width: 40px;
                font-size: 13px;
                border: 1px solid rgb(170, 170, 170);
                border-radius: 3px;
            }
            select {
                width: 80px;
                border: 1px solid rgb(170, 170, 170);
                border-radius: 3px;
                font-size: 14px;
            }
        }
    }
}

.sortable-row-tr {
    display: flex;
    .operation-input-div {
        display: flex;
        font-size: 12px;
        color: #505050;
        input {
            width: 100%;
            border: none;
            outline: none;
            font-size: 12px;
            color: #505050;
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0;
            }
            &:focus {
                border-bottom: 1px solid grey;
            }
            &:disabled {
                background-color: rgb(218, 218, 218);
            }
        }
        select {
            color: #505050;
            font-size: 12px;
            outline: none;
            border: none;
            border-radius: 3px;
            width: 100%;
            &:disabled {
                background-color: rgb(218, 218, 218);
            }
            option {
                &:disabled {
                    background-color: rgb(218, 218, 218);
                }
            }
        }
        svg {
            font-size: 15px;
            color: blue;
            cursor: pointer;
        }
    }
}

.no-port-found-div {
    width: 100%;
    height: 50px;
    text-align: center;
    display: flex;
    P {
        margin: auto;
        font-size: 13px;
        font-weight: normal;
    }
}

.bunker-supply-table {
    table {
        thead {
            tr {
                th {
                    padding: 0px 5px !important;
                    font-weight: normal;
                    font-size: 12px;
                    border-right: 1px solid rgb(207, 207, 207);
                    background-color: rgb(232, 233, 255);
                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 3px 5px !important;
                    font-weight: normal;
                    font-size: 12px;
                    border-bottom: none;
                    background-color: rgb(226, 226, 226);
                    select {
                        border-radius: 3px;
                        padding: 2px;
                        font-size: 11px;
                        option {
                            font-size: 11px;
                            border-radius: 3px;
                        }
                        &.select-color-orange {
                            background: #fff0ec;
                        }
                    }
                    // input {
                    //     width: 80px;
                    // }
                    input {
                        width: 95%;
                        border: none;
                        outline: none;
                        font-size: 11px;
                        border-radius: 3px;
                        // border-bottom: 1px solid rgb(184, 184, 184);
                        &::-webkit-inner-spin-button,
                        &::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            margin: 0;
                        }
                        border-bottom: 1px solid white;
                        &:focus {
                            border-color: grey;
                        }
                        &:disabled {
                            background-color: rgb(218, 218, 218);
                        }
                    }
                    svg {
                        font-size: 20px;
                        margin-right: 5px;
                        cursor: pointer;
                        color: #3f51b5;
                        &:last-child {
                            margin-right: 0px;
                        }
                    }
                    &:nth-child(10),
                    &:nth-child(11) {
                        background-color: rgb(255, 208, 196);
                    }
                }
            }
        }
    }
}

.add-query-operations-table {
    table {
        thead {
            tr {
                th {
                    width: 30px;
                    padding: 0px 5px !important;
                    font-weight: normal;
                    font-size: 12px;
                    border-right: 1px solid rgb(207, 207, 207);
                    background-color: rgb(232, 233, 255);
                    line-height: inherit;
                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    width: 40px;
                    min-width: 40px;
                    padding: 4px 2px !important;
                    font-weight: normal;
                    font-size: 12px;
                    border-bottom: none;
                    background-color: rgb(226, 226, 226);
                    * {
                        width: 95%;
                    }
                    select {
                        border-radius: 3px;
                        padding: 2px;
                        outline: none;
                        border: none;
                        color: rgb(48, 48, 48);
                        option {
                            border-radius: 3px;
                        }
                    }
                    input {
                        color: rgb(48, 48, 48);
                        display: block;
                        width: 95%;
                        border-radius: 3px;
                        outline: none;
                        border: none;
                    }
                    svg {
                        font-size: 20px;
                        margin-right: 5px;
                        cursor: pointer;
                        color: #3f51b5;
                        &:last-child {
                            margin-right: 0px;
                        }
                    }
                }
                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.add-three-dots {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    cursor: pointer;
}

.selected-fleet-row {
    //background: #ffe6da;
    background: #afe4ff;
    cursor: pointer;
}

.non-selected-fleet-row {
    cursor: pointer;
    &:hover {
        background: #dedede;
    }
    &.reference-fleet {
        background-color: #e8e9ea;
    }
}

.result-heading {
    padding: 5px;
}

.fleet-result-table {
    width: 100%;
    tr {
        background-color: rgb(231, 231, 231);
        th {
            border-radius: 3px;
            text-align: start;
            padding-left: 5px;
            width: 50%;
            font-size: 11px;
            font-weight: 500;
        }

        td {
            font-size: 11px;
            border-radius: 3px;
            // padding-left: 10px;

            input {
                width: 95%;
                border: none;
                outline: none;
                font-size: 11px;
                border-radius: 3px;
                // border-bottom: 1px solid rgb(184, 184, 184);
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    margin: 0;
                }
                border-bottom: 1px solid white;
                &:focus {
                    border-color: grey;
                }
                &:disabled {
                    background-color: rgb(218, 218, 218);
                }
            }
            span {
                color: #3f51b5;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    &.low-th-width {
        tr {
            th {
                width: 40% !important;
            }
        }
    }
    &.vesselDetails {
        th {
            height: 20px;
        }
        td {
            height: 20px;
            input {
                height: 90%;
            }
        }
    }
}
.custom-gbb-table-cell {
    display: flex;
    input {
        width: 60% !important;
    }
    span {
        font-size: 8px;
    }
}

.vessel-consumptions-header {
    margin-top: 5px;
}

.consumptions-headers {
    display: flex;
    justify-content: space-evenly;
    background-color: #d8d8d8;
    p {
        width: 25%;
        font-size: 11px;
    }
}

.consumptions-content {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    input {
        width: 25%;
        border: none;
        outline: none;
        font-size: 11px;
        border-bottom: 1px solid rgb(184, 184, 184);
       // background-color: rgb(255, 230, 218);
        background-color: #ffe4c4;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
        }
    }
    p {
        width: 25%;
    }
}

input {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
        -moz-appearance: textfield;
    }
}

input[type="number"] {
    appearance: none !important;
}

.vessel-name-div {
    width: 95%;
    display: flex;
    margin: 5px auto;
    input {
        padding: 0px;
        padding-left: 5px;
        width: 85%;
        margin-left: 8px;
        font-size: 12px;
        border: none;
        outline: none;
        border-bottom: 1px solid grey;
    }
}
.fuel-calculation-method {
    display: flex;
    margin: 10px 0;
    width: 100%;
    span {
        display: block;
        width: 60%;
        margin-left: 5px;
        font-size: 11px;
    }
    select {
        // width: 40%;
        color: #505050;
        font-size: 11px;
        outline: none;
        height: 20px;
        border: 1px solid grey;
        border-radius: 3px;
        margin-right: 5px;
        &:disabled {
            background-color: rgb(218, 218, 218);
        }
        option {
            font-size: 11px;
            border-radius: 3px;
            border: none;
            &:disabled {
                background-color: rgb(218, 218, 218);
            }
        }
    }
}

.result-details-errors {
    background-color: white;
    margin-top: 5px;
    border-radius: 3px;
    padding: 2px;
    margin-bottom: 30px;

    p {
        font-weight: 500;

        margin-top: 3px;
    }
    .errors-found-head {
        text-align: start;
    }
    .errors-found {
        color: red;
    }
    .no-errors-found {
        color: green;
        text-align: center;
    }
}

.vessel-scrubber-select {
    width: 99%;
    padding: 1px 0px;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    outline: none;
    font-size: 12px;
}

.margin-scrubber-select {
    padding: 1px 0px;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    outline: none;
    font-size: 11px;
}

.sync-bunker-switch {
    width: fit-content;
    float: right;
    margin-bottom: 10px;
}

.custom-editable-tablecell {
    width: 80px;
    input {
        display: block;
        width: 97%;
        height: 13px;
        font-size: 12px;
        border: 1px solid white;
        outline: none;
        border-radius: 3px;
        &:focus {
            border-color: grey;
        }
    }
}

.actions-buttons-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    background-color: lightsteelblue;
    svg {
        cursor: pointer;
        font-size: 20px;
        color: rgb(68, 68, 68);
    }
    .show-hide-calculator {
        padding-left: 8px;
        display: flex;
        svg {
            margin: auto;
            transform: rotate(90deg);
        }
    }
    .action-button-container {
        .calculate {
            margin-top: 2px;
            background-color: #3f51b5;
            color: white;
            border: none;
            outline: none;
            cursor: pointer;
            padding: 5px 10px;
            margin-right: 15px;
            border-radius: 3px;
        }
    }
}

.lower-prices-container {
    display: flex;
    justify-content: end;

    div:first-child {
        width: 400px;
        margin-right: 60px;
    }
}

.show-bunker-lower-prices {
    float: right;
    // margin-top: 20px;
    padding: 0px 5px 5px 5px;
    width: 350px;
    table {
        font-size: 12px;
        background-color: #e2e2e2;
        thead {
            tr {
                th {
                    font-weight: 500;
                    border-right: 1px solid grey;
                    padding-right: 10px;
                    &:last-child {
                        border: none;
                    }
                }
            }
        }

        tbody {
            tr {
                th {
                    font-weight: 500;
                    border-right: 1px solid grey;
                    padding-left: 10px;
                    padding-right: 10px;
                    text-align: left;
                    width: 300px;
                    &:last-child {
                        border: none;
                    }
                }
                td {
                    background-color: rgb(241, 241, 241);
                    width: 70px;
                    p {
                        width: 95%;
                    }
                    input {
                        width: 95%;
                        border: none;
                        outline: none;
                        font-size: 11px;
                        border-radius: 3px;
                        // border-bottom: 1px solid rgb(184, 184, 184);
                        &::-webkit-inner-spin-button,
                        &::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            margin: 0;
                        }
                        border-bottom: 1px solid white;
                        &:focus {
                            border-color: grey;
                        }
                        &:disabled {
                            background-color: #e2e2e2;
                        }
                    }
                }
            }
        }
    }
}

.input-type-checkbox {
    width: 14px !important;
    transform: scale(1);
}

.view-bunker-supply-table {
    text-align: center;
    color: #3f51b5;
    font-size: 11px;
    cursor: pointer;
}
.MuiAutocomplete-popper {
    div {
        font-size: 12px !important;
        ul {
            li {
                font-size: 12px !important;
            }
        }
    }
    .MuiAutocomplete-paper {
        ul {
            li {
                padding: 8px !important;
                span {
                    padding: 0px !important;
                    margin-right: 8px !important;
                }
            }
        }
    }
}

.add-fleet-modal-container {
    .add-new-fleet-line {
        cursor: pointer;
        color: white;
        background-color: #3f51b5;
        padding: 3px 5px;
        font-size: 14px;
        border-radius: 4px;
    }
    .MuiInputBase-input {
        height: 0.8rem;
        width: 200px;
        font-size: 12px;
    }
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-width: 1px !important;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px !important;
    }
    .MuiAutocomplete-tag {
        font-size: 12px !important;
    }

    .fleet-list-table {
        font-size: 12px;
        thead {
            background-color: #cecece;
            tr {
                th {
                    padding: 0px;
                    font-size: 12px;
                    .required-span {
                        color: red;
                        cursor: default;
                        font-size: 14px;
                    }
                }
            }
        }
        tbody {
            svg {
                font-size: 16px !important;
            }
            tr {
                td {
                    font-size: 12px;
                    padding: 0px;
                    .date-time-picker {
                        input {
                            height: 18px !important;
                            padding: 0;
                        }
                    }
                    .custom-input-field {
                        width: 60px;
                        border: 1px solid grey;
                        border-radius: 4px;
                        // padding-left: 8px;
                        font-size: 12px;
                        &.large-field {
                            display: flex;
                            min-width: 200px;
                            width: 100% !important;
                        }
                    }
                    .action-buttons {
                        margin-left: 10px;
                        font-size: 20px !important;
                        cursor: pointer;
                    }
                }
                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }
            }
        }
    }
    .save-all-vessel {
        float: right;
        margin-top: 10px;
        color: white;
        background-color: #3f51b5;
        padding: 3px 15px;
        font-size: 18px;
        border-radius: 4px;
        cursor: pointer;
    }
    .search-by-auto {
        color: white;
        background-color: #3f51b5;
        padding: 3px 15px;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;
    }
    .auto-add-fleet-label {
        display: flex;
        margin-bottom: 10px;
        font-size: 13px !important;
    }
    .MuiChip-deletable {
        margin: 0px 5px 0px 0px !important;
        height: 21px !important;
        .MuiChip-label {
            font-size: 10px;
            padding-right: 8px !important;
        }
        svg {
            width: 17px;
        }
    }
}

.refresh-distance-btn {
    background-color: #3f51b5;
    color: white;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 3px;
}

.vessel-list-editable-input {
    border: 1px solid #a3a3a3;
    width: 60px;
    font-size: 12px;
    border-radius: 4px;
}

.calculate-port-distance-btn {
    margin: auto;
    margin-top: 20px;
    border-radius: 3px;
    border: none;
    color: #fff;
    background-color: #3f51b5;
    outline: none;
    cursor: pointer;
    padding: 5px 10px;
    display: block;
}

.split-hire-dot {
    cursor: pointer;
    font-size: 40px;
    margin-left: 5px;
    position: absolute;
    top: -7px;
    margin-top: -10px;
    &.red {
        color: red;
    }
    &.green {
        color: green;
    }
}

.split-hire-table {
    width: 100%;
    border-radius: 4px;
    thead {
        tr {
            background-color: #cecece;
            th {
                padding: 5px;
            }
        }
    }
    tbody {
        tr {
            td {
                text-align: center;
                input {
                    border: 1px solid grey;
                    outline: none;
                    border-radius: 4px;
                    padding: 5px;
                }
            }
        }
    }
}

.add-split-hire {
    display: block;
    width: 50px;
    background-color: #3f51b5;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
}

.add-order-port-operation {
    margin-top: 10px;
    border-radius: 3px;
    border: none;
    outline: none;
    padding: 5px 10px;
    background-color: #3f51b5;
    color: white;
    cursor: pointer;
}

.port-change-div {
    width: 100%;
    height: 18px;
    p {
        border: 1px solid #cecece;
        height: 100%;
        background-color: white;
        border-radius: 3px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.email-parser-container {
    label {
        font-size: 13px;
    }
    .MuiChip-root {
        height: 22px;
        font-size: 10px;
        svg {
            width: 16px;
        }
    }

    .search-by-auto {
        background-color: #3f51b5;
        color: white;
        padding: 5px 20px;
        border-radius: 3px;
        cursor: pointer;
        margin-bottom: 5px !important;
    }
}
.margin-allowance-input {
    border-radius: 3px;
    outline: none;
    border: 1px solid grey;
    width: 60px !important;
}

.add-operation-modal-select {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    font-size: 16px;
    padding-left: 8px;
}

.add-operation-input {
    width: 98%;
    height: 35px;
    border-radius: 3px;
    border: 1px solid grey;
    padding-left: 8px;
    font-size: 16px;
}

.query-actions {
    .MuiSwitch-root {
        margin-top: -8px;
    }
}

.add-vessel-calculator {
    border: none;
    outline: none;
    background-color: #3f51b5;
    color: white;
    margin-top: 4px;
    cursor: pointer;
    border-radius: 4px;
}

.user-copy-container {
    select {
        height: 38px;
        margin-right: 8px;
        padding: 8px;
        border-radius: 5px;
        width: 200px;
    }
    .user-fleet-table {
        thead {
            tr {
                background-color: #cecece;
                th {
                    padding: 5px;
                    line-height: inherit;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 5px;
                    text-align: center;
                    input {
                        border: 1px solid grey;
                        outline: none;
                        border-radius: 4px;
                        padding: 5px;
                    }
                }
            }
        }
    }
}

.result-card-div {
    padding: 10px;
    .inner-div {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        padding: 0px 20px;
        cursor: pointer;
        background: #c5d3e4;
        color: #000000;
        p {
            font-size: 18px;
        }
    }
}

.modal-head-text {
    color: #2e2e2e;
    font-weight: 500;
    font-size: 18px;
    margin: 0px 0px 10px 0px;
    font-family: "Roboto", sans-serif;
}

.selected-vessel-remark {
    min-height: 200px;
}

.detail-map-view {
    min-height: 300px;
    margin-top: 10px;
}

.saved-fleet-wrapper {
    display: Grid;
    // justify-content: center;
    // width: 100%;
    input {
        width: 80%;
        margin: auto;
        border: 1px solid grey;
        outline: none;
        border-radius: 4px;
        // color: white;
        height: 35px;
        padding: 0px 10px;
        font-size: 16px;
    }
    p {
        width: 85%;
        margin: auto;
        color: grey;
        margin-top: 5px;
        font-size: 12px;
    }
}

.showmap-container {
    width: 100%;
    height: calc(100vh - 90px);
    .leaflet-container {
        height: 100%;
    }
}

.list-routes {
    display: flex;
    margin-left: 10px;
    .list-routes-item {
        background-color: #7784c9;
        padding: 0 5px;
        margin: 0 10px;
        border-radius: 8px;
        cursor: pointer;
        height: 40px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .route-item-div {
            /* height: 100%; */
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 30px;
                max-height: 30px;
            }
        }
        &.active-route {
            background-color: #ffffffba;
        }
    }
}

.searchable-tablerow {
    td {
        input {
            display: block;
            border-radius: 4px;
            border: 1px solid grey;
            outline: none;
            height: 20px;
            padding-left: 5px;
            min-width: 70px;
            max-width: 150px;
            width: 70px;
        }
        button {
            height: 25px;
            border-radius: 4px;
            border: none;
            outline: none;
            background: #3f51b5;
            color: white;
            width: 80px;
        }
    }
}

.fuel-type-bold-text {
    margin: 10px 0px;
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    color: #575757;
}

.range-load-quantity {
    display: flex;
    input {
        display: block;
        min-width: 40px !important;
        width: 60px !important;
        margin-right: 10px;
    }
}

.small-date-picker {
    width: fit-content !important;
    height: 20px !important;
    input {
        padding: 0px !important;
        height: 20px !important;
        padding-left: 4px !important;
        box-sizing: border-box;
        font-size: 13px !important;
        width: 80px !important;
        display: block !important;
        min-width: 80px !important;
        &::placeholder {
            color: rgb(36, 36, 36) !important;
        }
    }
}

.table-sort-arrow {
    font-size: 16px !important;
    cursor: pointer;
    &.active-arrow {
        color: red;
    }
}

.add-fleets-tabs {
    color: white;
    .Mui-selected {
        background: #565c85 !important;
    }
    .MuiTabs-indicator {
        background: #ffffff !important;
    }
}

.email-parser-table {
    max-height: 400px;
    height: 400px;
    min-height: 400px;
    overflow: auto;
}

.mui-selected-grey {
    .Mui-selected {
        background-color: #c1c1c1 !important;
    }
}

.email-content-container {
    font-size: 13px;
    div:nth-child(1) {
        background: #cccccc;
        div {
            padding: 5px 10px;
            p {
                font-weight: 500;
                span {
                    font-weight: 400;
                }
            }
            &:nth-child(1) {
                border-bottom: 1px solid rgb(83, 83, 83);
            }
        }
    }
    .email-content {
        min-height: 200px;
        background: #ebebeb;
        padding: 10px;
        max-height: 400px;
        overflow: auto;
        font-size: 13px;
    }
}

.autocomplete-mui {
    .MuiInputBase-root {
        padding: 0px !important;
        input {
            padding: 0px 0px 0px 5px !important;
            height: 20px !important;
        }
    }
}

.calculator-section-header {
    background: rgb(182, 182, 182);
    border-radius: 6px 6px 0px 0px;
}

.vessel-details-tables {
    width: 100%;
    th {
        font-size: 12px;
        background: #e7e7e7;
        text-align: start;
    }
    td {
        font-size: 12px;
        border-bottom: 1px solid #e7e7e7;
        input {
            display: block;
            width: 90%;
            outline: none;
            border: none;
            font-size: 12px;
            // border-bottom: 1px solid ;
        }
    }
}

.welcome-container {
    text-align: center;
    padding: 10px;
    .welcome-notes {
        min-height: 100px;
        margin-top: 50px;
    }
    .welcome-announcement {
        min-height: 100px
    }
    .navigation-container {
        width: 700px;
        justify-content: center;
        margin: auto;
        .navigation-div-item {
            display: flex;
            flex-wrap: wrap;
            .navigation-routes {
                width: 20%;
                padding: 10px;
                box-sizing: border-box;
                grid-gap: 2;
                // height: 150px;
                .main-container {
                    background-color: rgb(255, 255, 255);
                    padding: 10px;
                    box-sizing: border-box;
                    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
                    border-radius: 12px;
                    text-align: center;
                    cursor: pointer;
                    // height: 100%;
                    .navigation-icon {
                        width: 100%;
                        height: 80px;
                        padding: 10px;
                        box-sizing: border-box;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;

                        }
                    }
                    .navigation-route {
                        display: block;
                        margin-top: 10px;
                    }
                    &:hover {
                        background-color: rgb(141, 141, 141);
                    }
                }
            }
        }
    }
}